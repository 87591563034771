import cx from 'classnames';

import internalClasses from './Button.module.scss';

export const Button = ({
  classes = {},
  children,
  href,
  newTab = true,
  leadingIcon,
  trailingIcon,
  title
}) => {
  return (
    <a
      className={cx(internalClasses.button, classes.root)}
      href={href}
      target={newTab ? '_blank' : null}
      rel={newTab ? 'noopener noreferrer' : null}
      title={title}
    >
      {!!leadingIcon &&
        <span className={internalClasses['leading-icon']}>
          {leadingIcon}
        </span>
      }
      {children}
      {!!trailingIcon &&
        <span className={internalClasses['trailing-icon']}>
          {trailingIcon}
        </span>
      }
    </a>
  )
}