import classes from './Tab.module.scss';

export const Tab = ({
  children
}) => {
  return (
    <div className={classes['tab-container']}>
      <div className={classes.tab}>
        {children}
      </div>
    </div>
  )
}