import Icon from '@mdi/react';
import { mdiHeart } from '@mdi/js';

import { Button } from '../components/Button';
import MyRegistry from '../helpers/MyRegistry';

import classes from './Registry.module.scss';
import { Fragment, useEffect, useState } from 'react';
import { SplitBar } from '../components/SplitBar';

export const Registry = ({
}) => {
  const showRegistry = new Date() > new Date('January 4, 2024');

  console.log('Show Registry', showRegistry);

  const [ loaded, setLoaded ] = useState(false);

  useEffect(() => {
    if (!loaded && showRegistry) {
      MyRegistry();

      setLoaded(true);
    }
  }, []);

  return (
    <Fragment>    
      <Button
        href='http://paypal.me/harrisonepperson'
        trailingIcon={<Icon className={classes.heart} path={mdiHeart} size={.8}/>}
        newTab
        title='Help us buy our first home'
      >
        Help us buy our first home
      </Button>

      <SplitBar>
        Or help us furnish it!
      </SplitBar>
  
      {showRegistry &&
        <span className={classes['frame-container']}>
          <span id='script_myregistry_giftlist_iframe'></span>
        </span>
      }

      {!showRegistry &&
        <h2>We're still finalizing our registry.<br />Please check back during the New Year!</h2>
      }
    </Fragment>
  );
};