import { Fragment, useState } from 'react';
import { NavBar } from '../components/NavBar';
import { Home } from './Home';
import { Details } from './Details';
import { Welcome } from './Welcome';
import { Rsvp } from './RSVP';
import { Registry } from './Registry';
import { Tab } from '../components/Tab';

import classes from './index.module.scss';
import { Header } from '../components/Header';
import { useRef } from 'react';

export const Pages = ({}) => {
  const tabs = [
    { id: 'home', title: 'Home' },
    { id: 'details', title: 'Details' },
    { id: 'welcome', title: 'Welcome to Denver' },
    { id: 'rsvp', title: 'RSVP' },
    { id: 'registry', title: 'Registry' },
  ]

  const [ activeTab, setActiveTab ] = useState(tabs[0]);

  return (
    <Fragment>
      <Home
        activeTab={activeTab}
        onNavClick={setActiveTab}
        tabs={(() => {
          const homeTabs = [ ...tabs ];
          homeTabs[2].title = 'Welcome';

          return homeTabs;
        })()}
      />
      <div className={classes.slider}>
        <div className={classes.backdrop} />
        <Header />
        <NavBar
          activeTab={activeTab}
          onClick={setActiveTab}
          tabs={tabs}
        />
        <Tab>
          {activeTab.id === 'details' &&
            <Details />
          }
          {activeTab.id === 'welcome' &&
            <Welcome />
          }
          {activeTab.id === 'rsvp' &&
            <Rsvp />
          }
          {activeTab.id === 'registry' &&
            <Registry />
          }
        </Tab>
      </div>
      <div className={classes.footer}>Background image by pikisuperstar on Freepik</div>
    </Fragment>
  );
}