import cx from 'classnames';

import internalClasses from './SplitBar.module.scss';

export const SplitBar = ({
  children,
  classes = {}
}) => {
  return (
    <span className={cx(internalClasses['registry-divider'], classes.root)}>
      <hr />
      <h2>
        {children}
      </h2>
      <hr />
    </span>
  );
};