import { Fragment } from 'react';
import classes from './Header.module.scss';

export const Header = ({}) => {
  return (
    <Fragment>
      <h1 className={classes.names}>
        <span>Harrison</span>
        <span className={classes.ampersand}>&</span>
        <span>Madeleine</span>
      </h1>

      <div className={classes['date-banner']}>
        <span>3/14/2024</span>
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M14,6L10.25,11L13.1,14.8L11.5,16C9.81,13.75 7,10 7,10L1,18H23L14,6Z" />
          </svg>
        </span>
        <span>Silverthorne, CO</span>
      </div>
    </Fragment>
  );
};