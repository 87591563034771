import { Fragment } from 'react';
import classes from './Details.module.scss';
import Icon from '@mdi/react';
import { mdiCalendarPlus } from '@mdi/js';

import cx from 'classnames';
import { Button } from '../components/Button';
import { SplitBar } from '../components/SplitBar';

export const Details = ({}) => {

  const timeline = [
    { time: '4:00pm', event: 'Ceremony Begins' },
    { time: '5:00pm', event: 'Cocktail Hour' },
    { time: '6:00pm', event: 'Dinner' },
    { time: '8:00pm', event: 'First Dance' },
    { time: '8:15pm', event: 'Pie Cutting' },
    { time: '8:30pm', event: 'Open Dancing' },
    { time: '9:30pm', event: 'Final Send-off' },
  ]

  return (
    <Fragment>
      <h2 className={cx(classes['welcome-header'])}>
        We welcome you to join us at the Silverthorne Pavilion.
      </h2>
      <div className={classes['invite-block']}>
        <p>400 Blue River Pkwy</p>
        <p>Silverthorne, CO 80498</p>
        <p>4:00pm on March 14, 2024</p>
        <p>Please dress in semi-formal attire</p>

        <Button
          href='https://calendar.google.com/calendar/event?action=TEMPLATE&tmeid=Mm1jN2RoZjYxamZ1aW1icTNydHFvbGpmMTUgMzlmYTYzNTUwMWM4ODE3MDA4ODYxN2JhOWM4MzJiZDk0NTZlYjM1ZDUxODZhNjhkZmIwYzA2MTYxYzdiY2UyN0Bn&tmsrc=39fa635501c88170088617ba9c832bd9456eb35d5186a68dfb0c06161c7bce27%40group.calendar.google.com'
          title='Save to calendar'
          newTab
          leadingIcon={<Icon path={mdiCalendarPlus} size={1} />}
        >
          Save to Calendar
        </Button>
      </div>

      <SplitBar>
        Evening's Events
      </SplitBar>

      <ul className={classes.timeline}>
        {timeline.map(({ time, event }, i) => {
          return (
            <li key={i}>
              <span className={classes.time}>{time}</span>
              <span className={classes.event}>{event}</span>
            </li>
          )
        })}
      </ul>

      <SplitBar>
        Notes
      </SplitBar>

      <div className={classes['note-block']}>
        <p>Please refrain from taking photos during the ceremony. We will share our professional photos with everyone on this website, and we look forward to seeing your great shots of the reception!</p>
        <p>Winter conditions will likely still exist on the roads and at the venue. Cell reception can also be limited in the mountains. Please plan your route and dress accordingly.</p>
      </div>
    </Fragment>
  );
};
