import cx from 'classnames';

import internalClasses from './NavBar.module.scss';
import { Fragment, useEffect, useState } from 'react';
import Icon from '@mdi/react';
import { mdiClose, mdiMenu } from '@mdi/js';

export const NavBar = ({
  activeTab,
  classes = {},
  tabs,
  onClick
}) => {
  const [ isMobile, setIsMobile ] = useState(window.innerWidth <= 900);
  const [ showNav, setShowNav ] = useState(false);
  const [ expanded, setExpanded ] = useState(activeTab.id === 'home');

  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 900);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  useEffect(() => {
    setShowNav(!isMobile);
  }, [ isMobile ]);

  useEffect(() => {
    setExpanded(activeTab.id === 'home' && !showNav);
  }, [ activeTab, showNav ]);

  return (
    <Fragment>
      {isMobile &&
        <a className={cx(internalClasses['menu-button'], { [internalClasses.collapsed]: !expanded })} href='#' onClick={() => setShowNav((visible) => !visible)}>
          <Icon path={showNav ? mdiClose : mdiMenu} size={1} />
          <span className={internalClasses.menu}>Menu</span>
        </a>
      }
      <div className={cx(internalClasses.nav, classes?.root, { [internalClasses.hidden]: !showNav })}>
        <ul>
          {tabs.map((tab) => {
            const {
              id,
              title
            } = tab;

            return (
              <li
                className={cx({ [internalClasses.active]: id === activeTab.id, [classes.active]: id === activeTab.id })}
                key={id}
                onClick={() => {
                  setShowNav(!isMobile);
                  onClick(tab);
                }}
              >
                <span
                  className={cx(internalClasses.mountains, classes?.mountains)}
                />
                <span>
                  {title}
                </span>
              </li>
            );
          })}
        </ul>
      </div>
    </Fragment>
  );
}