import { Fragment } from 'react';
import classes from './RSVP.module.scss';

export const Rsvp = ({}) => {
  return (
    <Fragment>
      <h2>
        Please respond by Friday, January 26th.
      </h2>
      <span className={classes['frame-container']}>
        <iframe src='https://docs.google.com/forms/d/e/1FAIpQLScIhE25qFwDQU4I3Ti0JYtwCNsYuV2RBDkwis79HYbnO4OiAA/viewform?embedded=true' width='640' height='950' frameborder='0' marginheight='0' marginwidth='0'>Loading…</iframe>
      </span>
    </Fragment>
  );
};