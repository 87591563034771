import classes from './Welcome.module.scss';
import mapImage from '../assets/images/Map.png';
import denverTravel from '../assets/images/Denver_Travel.png';
import mountain from '../assets/images/Mountain.jpg';
import { Fragment } from 'react';
import { SplitBar } from '../components/SplitBar';


export const Welcome = ({}) => {
  return (
    <Fragment>
      <div className={classes['image-text-pair']}>
        <div className={classes['image-container']}>
          <img src={mapImage} />
        </div>
        <div className={classes.text}>
          <SplitBar>
            Travel
          </SplitBar>
          <p><strong>The Airport:</strong> Denver International Airport - 45 minutes to Denver</p>
          <p><strong>The Venue:</strong> Silverthorne Pavilion - 1 hour, 20 minutes from Denver</p>
        </div>
      </div>
      <div className={classes['image-text-pair']}>
        <div className={classes['image-container']}>
          {/* <img src={denverTravel} style={{ marginTop: '-190px' }}/> */}
          <img src={mountain} style={{ marginTop: '0' }}/>
        </div>
        <div className={classes.text}>
          <SplitBar>
            Things to do
          </SplitBar>
          <p>Botanic Gardens</p>
          <p>Musuem of Nature & Science</p>
          <p>Denver Art Museum</p>
          <p>Meow Wolf Convergence Station</p>
          <p>Snowsports</p>
          <p>Plenty of Hiking!</p>
        </div>
      </div>
    </Fragment>
  );
};