import classes from './Home.module.scss';
import Icon from "@mdi/react";
import { mdiImageFilterHdr } from "@mdi/js";
import { NavBar } from "../components/NavBar";

import cx from 'classnames';
import { Fragment, useEffect, useState } from 'react';

export const Home = ({
  activeTab,
  onNavClick,
  tabs
}) => {
  const [ isMobile, setIsMobile ] = useState(window.innerWidth <= 900);

  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 900);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <div className={cx(classes.slider, { [classes.hidden]: activeTab.id !== 'home' })}>
      <div className={classes['top-banner']}>
        {!isMobile &&
          <Fragment>
            <div className={classes['leading-space']} />
            <div className={classes.ampersand}>&</div>
          </Fragment>
        }

        <div className={classes['name-container']}>
          <h1>Harrison</h1>
          {isMobile && <div className={classes.ampersand}>
            &
          </div>}
          <h1>Madeleine</h1>
        </div>
        {!isMobile &&
          <Fragment>
            <div className={classes.spacer}></div>
            <NavBar
              activeTab={activeTab}
              classes={{
                active: classes.active,
                root: classes.nav,
                mountains: classes.mountains
              }}
              onClick={onNavClick}
              tabs={tabs}
            />
          </Fragment>
        }
      </div>
      <div className={classes['date-banner']}>
        <span>3/14/2024</span>
        <Icon path={mdiImageFilterHdr} size={2} />
        <span>Silverthorne, CO</span>
      </div>
    </div>
  );
};